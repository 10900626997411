var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "link-cell flex a-center",
      attrs: {
        href: _vm.data.link.includes("http")
          ? _vm.data.link
          : "https://" + _vm.data.link,
      },
    },
    [
      _c("span", { staticClass: "link-icon" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "14",
              height: "16",
              viewBox: "0 0 14 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M8.46875 16H2.5C1.12146 16 0 14.8785 0 13.5V5.03125C0 3.65271 1.12146 2.53125 2.5 2.53125H8.46875C9.84729 2.53125 10.9688 3.65271 10.9688 5.03125V13.5C10.9688 14.8785 9.84729 16 8.46875 16ZM2.5 3.78125C1.81079 3.78125 1.25 4.34204 1.25 5.03125V13.5C1.25 14.1892 1.81079 14.75 2.5 14.75H8.46875C9.15796 14.75 9.71875 14.1892 9.71875 13.5V5.03125C9.71875 4.34204 9.15796 3.78125 8.46875 3.78125H2.5ZM13.4688 11.9375V2.5C13.4688 1.12146 12.3473 0 10.9688 0H4.03125C3.68604 0 3.40625 0.279785 3.40625 0.625C3.40625 0.970215 3.68604 1.25 4.03125 1.25H10.9688C11.658 1.25 12.2188 1.81079 12.2188 2.5V11.9375C12.2188 12.2827 12.4985 12.5625 12.8438 12.5625C13.189 12.5625 13.4688 12.2827 13.4688 11.9375Z",
                fill: "#ACACAC",
              },
            }),
          ]
        ),
      ]),
      _c("span", { domProps: { textContent: _vm._s(_vm.data.link) } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }